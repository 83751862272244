import { CSSProperties, useEffect, useState } from "react";
import { CloseIcon, HelpIcon } from "../../../icons/icons";
import styles from "./PropertyTypeModal.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  selectedPropertyTypeCard: any;
  onClose: () => void;
}

const PropertyTypeModal: React.FC<Props> = ({
  selectedPropertyTypeCard,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [selectedAreaUnit, setSelectedAreaUnit] = useState("SQM");
  const [isMobileWidth, setIsMobileWidth] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileWidth(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isMobileWidth) {
    return null;
  }

  const propertyTypeCardStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
  };
  return (
    <div className={styles.container}>
      <button className={styles.closeButton} onClick={onClose}>
        <CloseIcon color="#fff" />
      </button>
      <div
        className={`${styles.propertyTypeCard} ${styles.appearAnimation}`}
        style={propertyTypeCardStyle}
      >
        <div className={styles.propertyTypeCardHeader}>
          <HelpIcon color="#D27BFA" />
          <div className={styles.propertyTypeCardHeaderTitle}>
            {selectedPropertyTypeCard}
          </div>
        </div>
        <div className={styles.propertyTypeCardContent}>
          <div className={styles.contentRow}>
            <div>{t("propertyFilters.bedrooms")}</div>
            <div>4</div>
          </div>
          <div className={styles.contentRow}>
            <div>{t("propertyFilters.bathrooms")}</div>
            <div>4</div>
          </div>
          <div className={styles.contentRow}>
            <div>{t("propertyFilters.floor")}</div>
            <div>4</div>
          </div>
          <div className={styles.contentRow}>
            <div>{t("propertyFilters.totalArea")}</div>
            <div>
              <span>230.50</span>
              <select
                value={selectedAreaUnit}
                onChange={(e) => setSelectedAreaUnit(e.target.value)}
                className={styles.areaSelect}
              >
                <option value="SQM">SQM</option>
                <option value="SQFT">SQFT</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyTypeModal;
