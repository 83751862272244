import { CSSProperties, useContext } from "react";
import styles from "./CopyrightText.module.css";
import { useTranslation } from "react-i18next";
import { PageContext } from "../../../contexts";

const CopyrightText: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { currentPage } = useContext(PageContext);

  const containerStyle: CSSProperties & { [key: string]: any } = {
    right: currentLanguage === "ar" ? "2%" : "unset",
    left: currentLanguage === "ar" ? "unset" : "2%",
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
  };

  if (currentPage === "HomePage") {
    return null;
  }
  return (
    <a
      href="http://www.virtualvisions.studio/"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.container}
      style={containerStyle}
    >
      {t(`copyright`)}
    </a>
  );
};

export default CopyrightText;
