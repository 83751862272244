import { PageContext, Pages } from "../../../contexts";
import { useContext } from "react";
import styles from "./MapLocation.module.css";
import { isMobile } from "react-device-detect";

interface Props {
  locationName: string;
  targetPage: Pages;
  position: {
    mobile: { top: string; left: string };
    desktop: { top: string; left: string };
  };
}

const MapLocation: React.FC<Props> = (props) => {
  const positionStyle = isMobile
    ? props.position.mobile
    : props.position.desktop;
  const pageContext = useContext(PageContext);

  const { navigateToPage, setActiveButton } = pageContext;

  const handleClick = () => {
    navigateToPage(props.targetPage);
    setActiveButton(null);
  };

  return (
    <div
      className={styles.wrapper}
      style={{ top: positionStyle.top, left: positionStyle.left }}
    >
      <button className={styles.locationWrapepr} onClick={handleClick}>
        <span className={styles.locationName}>{props.locationName}</span>
      </button>
      <div className={styles.pinArea}>
        <div className={styles.pin} />
      </div>
    </div>
  );
};

export default MapLocation;
