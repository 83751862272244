import styles from "./Logo.module.css";
import { LogoIcon } from "../../../icons/icons";
import { useContext } from "react";
import { PageContext, Pages } from "../../../contexts";

export default function Logo() {
  const { navigateToPage } = useContext(PageContext);

  const handleLogoClick = () => {
    navigateToPage(Pages.HomePage);
  };

  return (
    <div className={styles.container} onClick={handleLogoClick}>
      <LogoIcon />
    </div>
  );
}
