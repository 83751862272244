import { CSSProperties, useContext, useState } from "react";
import { CloseIcon, HelpIcon, InfoIcon } from "../../../icons/icons";
import RangeSlider from "../RangeSlider/RangeSlider";
import styles from "./PropertyFilters.module.css";
import { PageContext } from "../../../contexts";
import { useTranslation } from "react-i18next";
import MobileTabletRangeSlider from "../MobileTabletRangeSlider/MobileTabletRangeSlider";
import { isMobile } from "react-device-detect";
import PropertyTypeModal from "../../Modal/PropertyTypeModal/PropertyTypeModal";

interface Props {
  locationName: string;
}

const PropertyFilters: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { setActiveButton } = useContext(PageContext);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(
    t("propertyFilters.available")
  );
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedPropertyTypeCard, setSelectedPropertyTypeCard] = useState<
    string | null
  >(null);
  const [modalSelectedPropertyTypeCard, setModalSelectedPropertyTypeCard] =
    useState<string | null>(null);
  const [isHoveredA, setIsHoveredA] = useState(false);
  const [isHoveredB, setIsHoveredB] = useState(false);
  const [isHoveredC, setIsHoveredC] = useState(false);
  const [showPropertyTypeModal, setShowPropertyTypeModal] = useState(false);
  const [selectedAreaUnit, setSelectedAreaUnit] = useState("SQM");
  const [isHovered, setIsHovered] = useState(false);

  const [sliderKey, setSliderKey] = useState(0);

  const handleStatusClick = (status: string) => {
    setSelectedStatus(status);
  };

  // Function to open the modal with the selected property type card
  const openModal = (type: string) => {
    setShowPropertyTypeModal(true);
    setModalSelectedPropertyTypeCard(type);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowPropertyTypeModal(false);
    setModalSelectedPropertyTypeCard(null); // Reset the selected card when closing the modal
  };

  const handleTypeHover = (type: string) => {
    setSelectedPropertyTypeCard(type);
  };

  const getStatusButtonClass = (status: string) => {
    return selectedStatus === status
      ? `${styles.propertStatusButton} ${styles.selected}`
      : styles.propertStatusButton;
  };

  const getTypeButtonClass = (type: string) => {
    return selectedType === type
      ? `${styles.propertyType} ${styles.selected}`
      : styles.propertyType;
  };

  const getColorForIcon = (type: string, isHovered: boolean) => {
    if (selectedType === type) {
      return "#d27bfa";
    }
    if (isHovered) {
      return "#fff";
    }
    return "#3f3e45";
  };

  const handleClear = () => {
    setSelectedStatus(t("propertyFilters.available"));
    setSelectedType(null);
    setSelectedPropertyTypeCard(null);
    handleReset();
  };

  const handleReset = () => {
    setSliderKey((prevKey) => prevKey + 1);
  };

  const handleClose = () => {
    setActiveButton(null);
  };

  const wrapperStyle: CSSProperties & { [key: string]: any } = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    right: currentLanguage === "ar" ? "2%" : "unset",
    left: currentLanguage === "ar" ? "unset" : "2%",
    "--direction": currentLanguage === "ar" ? "100%" : "-100%",
  };

  const propertyTypeCardStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
  };

  const containerStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
  };

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      <div className={styles.container} style={containerStyle}>
        <div className={styles.header}>
          <div>{t("propertyFilters.filters")}</div>
          <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon color={isHovered ? "#000" : "#fff"} />
          </button>
        </div>
        <div className={styles.propertStatusContainer}>
          <button
            className={getStatusButtonClass(
              `${t("propertyFilters.available")}`
            )}
            onClick={() =>
              handleStatusClick(`${t("propertyFilters.available")}`)
            }
          >
            {t("propertyFilters.available")}
          </button>
          <button
            className={getStatusButtonClass(`${t("propertyFilters.booked")}`)}
            onClick={() => handleStatusClick(`${t("propertyFilters.booked")}`)}
          >
            {t("propertyFilters.booked")}
          </button>
          <button
            className={getStatusButtonClass(`${t("propertyFilters.sold")}`)}
            onClick={() => handleStatusClick(`${t("propertyFilters.sold")}`)}
          >
            {t("propertyFilters.sold")}
          </button>
        </div>
        <div className={styles.propertyText}>
          {t("propertyFilters.address")}
        </div>
        <div className={styles.columnWrapper}>
          <div className={styles.rowWrapper}>
            <div className={styles.label}>{t("propertyFilters.unitType")}</div>
          </div>
          <div className={styles.propertyTypeWrapper}>
            <button
              className={getTypeButtonClass(t("propertyFilters.typeA"))}
              onMouseEnter={() => {
                handleTypeHover(t("propertyFilters.typeA"));
                setIsHoveredA(true);
              }}
              onMouseLeave={() => {
                setSelectedPropertyTypeCard(null);
                setIsHoveredA(false);
              }}
              onClick={() => openModal(t("propertyFilters.typeA"))}
            >
              {t("propertyFilters.typeA")}
              <div className={styles.ButtonIcon}>
                <InfoIcon
                  color={getColorForIcon(
                    t("propertyFilters.typeA"),
                    isHoveredA
                  )}
                />
              </div>
            </button>
            <button
              className={getTypeButtonClass(t("propertyFilters.typeB"))}
              onMouseEnter={() => {
                handleTypeHover(t("propertyFilters.typeB"));
                setIsHoveredB(true);
              }}
              onMouseLeave={() => {
                setSelectedPropertyTypeCard(null);
                setIsHoveredB(false);
              }}
              onClick={() => openModal(t("propertyFilters.typeB"))}
            >
              {t("propertyFilters.typeB")}
              <div className={styles.ButtonIcon}>
                <InfoIcon
                  color={getColorForIcon(
                    t("propertyFilters.typeB"),
                    isHoveredB
                  )}
                />
              </div>
            </button>
            <button
              className={getTypeButtonClass(t("propertyFilters.typeC"))}
              onMouseEnter={() => {
                handleTypeHover(t("propertyFilters.typeC"));
                setIsHoveredC(true);
              }}
              onMouseLeave={() => {
                setSelectedPropertyTypeCard(null);
                setIsHoveredC(false);
              }}
              onClick={() => openModal(t("propertyFilters.typeC"))}
            >
              {t("propertyFilters.typeC")}
              <div className={styles.ButtonIcon}>
                <InfoIcon
                  color={getColorForIcon(
                    t("propertyFilters.typeC"),
                    isHoveredC
                  )}
                />
              </div>
            </button>
          </div>
        </div>
        <div className={styles.columnWrapper}>
          <div className={styles.label}>{t("propertyFilters.priceRange")}</div>

          {isMobile ? (
            <MobileTabletRangeSlider
              key={sliderKey}
              availability={[
                0.1, 0.3, 0.5, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9,
                0.6, 0.4,
              ]}
              label="Currency"
              options={["USD", "EUR", "GBP"]}
              minValue={0}
              maxValue={6000}
            />
          ) : (
            <RangeSlider
              key={sliderKey}
              availability={[
                0.1, 0.3, 0.5, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9,
                0.6, 0.4,
              ]}
              label="Currency"
              options={["USD", "EUR", "GBP"]}
              minValue={0}
              maxValue={6000}
            />
          )}
        </div>
        <div className={styles.columnWrapper}>
          <div className={styles.label}>{t("propertyFilters.plotArea")}</div>

          {isMobile ? (
            <MobileTabletRangeSlider
              key={sliderKey}
              availability={[
                0.1, 0.3, 0.5, 0.7, 0.9, 1.6, 1.4, 1.7, 1.9, 0.6, 0.4, 0.7, 0.9,
                0.6, 0.4,
              ]}
              label="Size Unit"
              options={["sqm", "sqft"]}
              minValue={0}
              maxValue={1000}
            />
          ) : (
            <RangeSlider
              key={sliderKey}
              availability={[
                0.1, 0.3, 0.5, 0.7, 0.9, 1.6, 1.4, 1.7, 1.9, 0.6, 0.4, 0.7, 0.9,
                0.6, 0.4,
              ]}
              label="Size Unit"
              options={["sqm", "sqft"]}
              minValue={0}
              maxValue={1000}
            />
          )}
        </div>
        <div className={styles.buttonWrapper}>
          <button className={styles.applyButton}>
            {t("propertyFilters.applyFilters")}
          </button>
          <button className={styles.clearButton} onClick={handleClear}>
            {t("propertyFilters.clearFilters")}
          </button>
        </div>
      </div>
      {showPropertyTypeModal ? (
        <PropertyTypeModal
          selectedPropertyTypeCard={modalSelectedPropertyTypeCard}
          onClose={closeModal}
        />
      ) : null}
      {selectedPropertyTypeCard && (
        <div
          className={`${styles.propertyTypeCard} ${styles.appearAnimation}`}
          style={propertyTypeCardStyle}
        >
          <div className={styles.propertyTypeCardHeader}>
            <HelpIcon color="#D27BFA" />
            <div className={styles.propertyTypeCardHeaderTitle}>
              {selectedPropertyTypeCard}
            </div>
          </div>
          <div className={styles.propertyTypeCardContent}>
            <div className={styles.contentRow}>
              <div>{t("propertyFilters.bedrooms")}</div>
              <div>4</div>
            </div>
            <div className={styles.contentRow}>
              <div>{t("propertyFilters.bathrooms")}</div>
              <div>4</div>
            </div>
            <div className={styles.contentRow}>
              <div>{t("propertyFilters.floor")}</div>
              <div>4</div>
            </div>
            <div className={styles.contentRow}>
              <div>{t("propertyFilters.totalArea")}</div>
              <div>
                <span>230.50</span>
                <select
                  value={selectedAreaUnit}
                  onChange={(e) => setSelectedAreaUnit(e.target.value)}
                  className={styles.areaSelect}
                >
                  <option value="SQM">SQM</option>
                  <option value="SQFT">SQFT</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyFilters;
