import React, { CSSProperties, useContext } from "react";
import styles from "./BreadCrumb.module.css";
import { PageContext } from "../../../contexts";
import {
  BreadCrumbArrowRightIcon,
  BreadCrumbArrowLeftIcon,
} from "../../../icons/icons";
import BackButton from "../BackButton/BackButton";
import { useTranslation } from "react-i18next";

const Breadcrumb: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { breadcrumb, handleGoBack, navigateToBreadcrumb } =
    useContext(PageContext);

  const containerStyle: CSSProperties = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
  };

  const ArrowIcon =
    currentLanguage === "ar"
      ? BreadCrumbArrowLeftIcon
      : BreadCrumbArrowRightIcon;

  return (
    <nav className={styles.container} style={containerStyle}>
      <BackButton goBack={handleGoBack} />
      <div className={styles.separator} />
      {breadcrumb.map((page, index) => (
        <React.Fragment key={index}>
          <div
            className={
              index === breadcrumb.length - 1
                ? styles.activeText
                : styles.inactiveText
            }
            onClick={() => {
              if (navigateToBreadcrumb) {
                navigateToBreadcrumb(page);
              }
            }}
          >
            {page}
          </div>
          {index < breadcrumb.length - 1 && (
            <div className={styles.arrowWrapper}>
              <ArrowIcon />
            </div>
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumb;
