import React, { useState, useEffect } from "react";
import { PageContext, Pages } from "./PageContext";
import { getPageDisplayNames } from "../utils/pageNames";
import { useTranslation } from "react-i18next";

interface PageProviderProps {
  children: React.ReactNode;
}

export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const translatedEarth = t("locations.earth");
  const [currentPage, setCurrentPage] = useState(Pages.HomePage);
  const [breadcrumb, setBreadcrumb] = useState<string[]>([translatedEarth]);
  const [pageHistory, setPageHistory] = useState<Pages[]>([Pages.GlobePage]);
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("Kitchen");
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [isUnitInformationsOpen, setIsUnitInformationsOpen] = useState(false);
  const [isVRMode, setIsVRMode] = useState(false);
  const [areCaptionsOn, setAreCaptionsOn] = useState(true);
  const [activeAmenitiesButtons, setActiveAmenitiesButtons] = useState<{
    [key: string]: boolean;
  }>({
    hospital: false,
    park: false,
    retail: false,
    pocketPark: false,
    mosque: false,
    school: false,
  });
  const [activeFilterButtons, setActiveFilterButtons] = useState<{
    [key: string]: boolean;
  }>({
    landmarks: false,
    residential: false,
    retail: false,
    health: false,
    business: false,
    religious: false,
    education: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Update the selectedRoom when the language changes
    if (i18n.language === "ar") {
      setSelectedRoom(t("rooms.kitchen"));
    } else {
      setSelectedRoom("Kitchen");
    }
  }, [i18n.language]);

  useEffect(() => {
    // Function to update the breadcrumb based on the current language
    const updateBreadcrumbLanguage = () => {
      const displayNames = getPageDisplayNames(t);
      const newBreadcrumb = pageHistory.map(
        (page) => displayNames[page] || "Unknown Page"
      );
      setBreadcrumb(newBreadcrumb);
    };

    // Update the breadcrumb when the language changes
    updateBreadcrumbLanguage();
  }, [t, pageHistory]);

  const toggleVRMode = () => {
    setIsVRMode(!isVRMode);
  };

  const navigateToPage = (page: Pages) => {
    setCurrentPage(page);
    if (page === Pages.GlobePage) {
      setPageHistory([Pages.GlobePage]);
      setBreadcrumb([t("locations.earth")]);
    } else {
      setPageHistory([...pageHistory, page]);
    }
    setActiveAmenitiesButtons({
      hospital: false,
      park: false,
      retail: false,
      pocketPark: false,
      mosque: false,
      school: false,
    });
    setActiveFilterButtons({
      landmarks: false,
      residential: false,
      retail: false,
      health: false,
      business: false,
      religious: false,
      education: false,
    });
    setIsVRMode(false);
  };

  const goBack = () => {
    if (pageHistory.length > 1) {
      const newHistory = pageHistory.slice(0, -1);
      setPageHistory(newHistory);
      setCurrentPage(newHistory[newHistory.length - 1]);
    }
  };

  const addBreadcrumb = (page: Pages) => {
    const displayNames = getPageDisplayNames(t);
    const displayName = displayNames[page] || "Unknown Page";
    if (!breadcrumb.includes(displayName)) {
      setBreadcrumb([...breadcrumb, displayName]);
    }
  };

  const navigateToBreadcrumb = (pageName: string) => {
    const index = breadcrumb.indexOf(pageName);
    if (index !== -1) {
      const newBreadcrumb = breadcrumb.slice(0, index + 1);
      const newHistory = pageHistory.slice(0, index + 1);
      setBreadcrumb(newBreadcrumb);
      setPageHistory(newHistory);
      setCurrentPage(newHistory[newHistory.length - 1]);
    }
    setIsVRMode(false);
  };

  const handleGoBack = () => {
    if (pageHistory.length > 1) {
      const newHistory = pageHistory.slice(0, -1);
      const previousPage = newHistory[newHistory.length - 1]; // Get the last page in the new history

      // If the previous page is the Global page, reset the breadcrumb
      if (previousPage === Pages.GlobePage) {
        setBreadcrumb([t("locations.earth")]);
      } else {
        setBreadcrumb(breadcrumb.slice(0, -1)); // Remove the last breadcrumb
      }

      setPageHistory(newHistory);
      setCurrentPage(previousPage);
      setActiveButton(null);
      setIsUnitInformationsOpen(false);
      setAreCaptionsOn(true);
      setActiveAmenitiesButtons({
        hospital: false,
        park: false,
        retail: false,
        pocketPark: false,
        mosque: false,
        school: false,
      });
      setActiveFilterButtons({
        landmarks: false,
        residential: false,
        retail: false,
        health: false,
        business: false,
        religious: false,
        education: false,
      });
      setIsVRMode(false);
    }
  };

  const toggleBurgerMenu = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const toggleUnitInformations = () => {
    setIsUnitInformationsOpen(!isUnitInformationsOpen);
  };

  const toggleCaptions = () => {
    setAreCaptionsOn(!areCaptionsOn);
  };

  return (
    <PageContext.Provider
      value={{
        currentPage,
        breadcrumb,
        navigateToPage,
        goBack,
        addBreadcrumb,
        handleGoBack,
        activeButton,
        setActiveButton,
        isBurgerMenuOpen,
        toggleBurgerMenu,
        isVRMode,
        toggleVRMode,
        setIsVRMode,
        selectedRoom,
        setSelectedRoom,
        selectedFilter,
        setSelectedFilter,
        areCaptionsOn,
        toggleCaptions,
        navigateToBreadcrumb,
        isUnitInformationsOpen,
        toggleUnitInformations,
        activeAmenitiesButtons,
        setActiveAmenitiesButtons,
        activeFilterButtons,
        setActiveFilterButtons,
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
