import { CSSProperties, useContext } from "react";
import { PageContext } from "../../../contexts";
import Breadcrumb from "../BreadCrumb/BreadCrumb";
import CaptionButton from "../CaptionButton/CaptionButton";
import Logo from "../Logo/Logo";
import VRModeButton from "../VRModeButton/VRModeButton";
import styles from "./TopBar.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  isVRMode?: boolean;
}

const TopBar: React.FC<Props> = ({ isVRMode }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { currentPage } = useContext(PageContext);

  if (currentPage === "HomePage") {
    return null;
  }

  const containerStyle: CSSProperties = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    left: currentLanguage === "ar" ? "unset" : "2%",
    right: currentLanguage === "ar" ? "2%" : "unset",
  };

  return (
    <div className={styles.container} style={containerStyle}>
      <Logo />
      {currentPage !== "GlobePage" ? <Breadcrumb /> : null}

      {currentPage === "HousePlanPage" ? <VRModeButton /> : null}
      {currentPage === "HousePlanPage" && !isVRMode ? <CaptionButton /> : null}
    </div>
  );
};

export default TopBar;
