import styles from "./Register.module.css";
import { CSSProperties, useContext, useRef, useState } from "react";
import { PageContext } from "../../../contexts";
import { CallbackIcon, CloseIcon } from "../../../icons/icons";
import CallbackForm from "../../Form/CallbackForm/CallbackForm";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const Register: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { setActiveButton } = useContext(PageContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const containerStyle: CSSProperties & { [key: string]: any } = {
    right: currentLanguage === "ar" ? "unset" : "0%",
    left: currentLanguage === "ar" ? "0%" : "unset",
    "--direction": currentLanguage === "ar" ? "-100%" : "100%",
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
  };

  const closeButtonStyle: CSSProperties = {
    right: currentLanguage === "ar" ? "unset" : "3%",
    left: currentLanguage === "ar" ? "3%" : "unset",
  };

  const handleClose = () => {
    setActiveButton(null); // Reset the activeButton state
  };

  const handleCallHelp = () => {
    setActiveButton(t(`nav.help`)); // Reset the activeButton state
  };

  if (isMobile) {
    return null;
  }

  return (
    <div className={styles.container} style={containerStyle} ref={containerRef}>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={styles.closeButton}
        style={closeButtonStyle}
        onClick={handleClose}
      >
        <CloseIcon color={isHovered ? "#000" : "#fff"} />
      </button>
      <div className={styles.header}>
        <span className={styles.title}>
          {t(`propertyInformations.register`)}
        </span>
      </div>
      <p className={styles.description}>{t(`callback.description`)}</p>
      <CallbackForm />
      <div className={styles.bottomText}>{t(`callback.question`)}</div>
      <div className={styles.helpWrapper}>
        {t(`callback.check`)} {/* Added a space here */}
        <div className={styles.help} onClick={handleCallHelp}>
          {t(`callback.help`)}
        </div>
      </div>
    </div>
  );
};

export default Register;
