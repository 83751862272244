import styles from "./MapFiltersButton.module.css";
import {
  LandmarksIcon,
  ResidentialIcon,
  RetailIcon,
  HealthIcon,
  BusinessIcon,
  ReligiousIcon,
  EducationIcon,
} from "../../../icons/icons";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  isActive: boolean;
  toggleActive: () => void;
}

const MapFiltersButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const getIconColor = (isActive: boolean, defaultColor: string) => {
    return isActive ? "#fff" : defaultColor;
  };

  const getBackgroundColor = () => {
    if (!props.isActive) return "";
    switch (props.name) {
      case t(`filters.landmarks`):
        return "#f89248";
      case t(`filters.residential`):
        return "#ff6767";
      case t(`filters.retail`):
        return "#F8D648";
      case t(`filters.health`):
        return "#7abc1d";
      case t(`filters.business`):
        return "#6c82f7";
      case t(`filters.religious`):
        return "#3353F8";
      case t(`filters.education`):
        return "#987BFA";
      default:
        return "";
    }
  };

  const renderIcon = () => {
    switch (props.name) {
      case t(`filters.landmarks`):
        return (
          <LandmarksIcon color={getIconColor(props.isActive, "#f89248")} />
        );
      case t(`filters.residential`):
        return (
          <ResidentialIcon color={getIconColor(props.isActive, "#ff6767")} />
        );
      case t(`filters.retail`):
        return <RetailIcon color={getIconColor(props.isActive, "#F8D648")} />;
      case t(`filters.health`):
        return <HealthIcon color={getIconColor(props.isActive, "#7abc1d")} />;
      case t(`filters.business`):
        return <BusinessIcon color={getIconColor(props.isActive, "#6c82f7")} />;
      case t(`filters.religious`):
        return (
          <ReligiousIcon color={getIconColor(props.isActive, "#3353F8")} />
        );
      case t(`filters.education`):
        return (
          <EducationIcon color={getIconColor(props.isActive, "#987BFA")} />
        );
      default:
        return null;
    }
  };

  return (
    <button
      className={`${styles.container} ${
        props.isActive ? styles.activeButton : ""
      }`}
      onClick={props.toggleActive}
      style={{ backgroundColor: getBackgroundColor() }}
    >
      {renderIcon()}
      <span
        className={`${styles.text} ${props.isActive ? styles.activeText : ""}`}
      >
        {props.name}
      </span>
    </button>
  );
};

export default MapFiltersButton;
