import { useContext } from "react";
import styles from "./BuergerMenu.module.css";
import { BurgerIcon } from "../../../icons/icons";
import { PageContext } from "../../../contexts";
import { useTranslation } from "react-i18next";

export default function BuergerMenu() {
  const { t } = useTranslation();
  const { toggleBurgerMenu } = useContext(PageContext);

  return (
    <button className={styles.container} onClick={toggleBurgerMenu}>
      <BurgerIcon />
      <span className={styles.text}>{t(`button.menu`)}</span>
    </button>
  );
}
