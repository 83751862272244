import styles from "./AmenitiesButton.module.css";
import {
  RetailIcon,
  ReligiousIcon,
  EducationIcon,
  HospitalIcon,
  ParkIcon,
  PocketParkIcon,
} from "../../../icons/icons";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  isActive: boolean;
  toggleActive: () => void;
}

const AmenitiesButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const getIconColor = (isActive: boolean, defaultColor: string) => {
    return isActive ? "#fff" : defaultColor;
  };

  const getBackgroundColor = () => {
    if (!props.isActive) return "";
    switch (props.name) {
      case t(`amenities.hospital`):
        return "#ff6767";
      case t(`amenities.park`):
        return "#95d935";
      case t(`amenities.retail`):
        return "#D27BF9";
      case t(`amenities.pocketPark`):
        return "#6780ff";
      case t(`amenities.mosque`):
        return "#F8D648";
      case t(`amenities.school`):
        return "#987BFA";
      default:
        return "";
    }
  };

  const renderIcon = () => {
    switch (props.name) {
      case t(`amenities.hospital`):
        return <HospitalIcon color={getIconColor(props.isActive, "#ff6767")} />;
      case t(`amenities.park`):
        return <ParkIcon color={getIconColor(props.isActive, "#95d935")} />;
      case t(`amenities.retail`):
        return <RetailIcon color={getIconColor(props.isActive, "#D27BF9")} />;
      case t(`amenities.pocketPark`):
        return (
          <PocketParkIcon color={getIconColor(props.isActive, "#6780ff")} />
        );
      case t(`amenities.mosque`):
        return (
          <ReligiousIcon color={getIconColor(props.isActive, "#F8D648")} />
        );
      case t(`amenities.school`):
        return (
          <EducationIcon color={getIconColor(props.isActive, "#987BFA")} />
        );
      default:
        return null;
    }
  };

  return (
    <button
      className={`${styles.container} ${
        props.isActive ? styles.activeButton : ""
      }`}
      onClick={props.toggleActive}
      style={{ backgroundColor: getBackgroundColor() }}
    >
      {renderIcon()}
      <span
        className={`${styles.text} ${props.isActive ? styles.activeText : ""}`}
      >
        {props.name}
      </span>
    </button>
  );
};

export default AmenitiesButton;
