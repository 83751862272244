import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PageProvider } from "./contexts";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./style/fonts.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
    <PageProvider>
      <App />
    </PageProvider>
  </I18nextProvider>
);

reportWebVitals();
