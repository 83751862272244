import { useContext, useEffect, useState } from "react";
import { PageContext, Pages } from "../../contexts";
import styles from "./HousePlanPage.module.css";
import PropertyInformationsButton from "../../components/Shared/PropertyInformationsButton/PropertyInformationsButton";
import PropertyInformations from "../../components/Shared/PropertyInformations/PropertyInformations";
import NavigationTab from "../../components/Shared/NavigationTab/NavigationTab";
import FloorTab from "../../components/Shared/FloorTab/FloorTab";
import RoomTab from "../../components/Shared/RoomTab/RoomTab";
import { isMobile as isDeviceMobile } from "react-device-detect";
import HorizontalTabletHousePlanOptions from "../../components/Shared/HorizontalTabletHousePlanOptions/HorizontalTabletHousePlanOptions";
import { useTranslation } from "react-i18next";
import MobileTabletPropertyInformations from "../../components/Shared/MobileTabletPropertyInformations/MobileTabletPropertyInformations";
import Captions from "../../components/Shared/Captions/Captions";
import Compass, { Position } from "../../components/Shared/Compass/Compass";
import { AnimatePresence, motion } from "framer-motion";

const HousePlanPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { addBreadcrumb, isVRMode, setSelectedRoom, activeButton } =
    useContext(PageContext);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [isTabletLandscape, setIsTabletLandscape] = useState(false);

  const compassPosition: Position = {
    mobile: {
      en: {
        right: "3%",
        top: "9%",
      },
      ar: {
        left: "3%",
        top: "9%",
      },
    },
    desktop: {
      en: {
        bottom: "5%",
        left: "2%",
      },
      ar: {
        bottom: "5%",
        right: "2%",
      },
    },
  };

  const slideVariants = {
    hidden: {
      opacity: 0,
      x: currentLanguage === "ar" ? "-100%" : "100%",
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: currentLanguage === "ar" ? "-100%" : "100%",
    },
  };

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };

    // Initial set
    setBodyHeight();

    // Update on resize
    window.addEventListener("resize", setBodyHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", setBodyHeight);
    };
  }, []);

  useEffect(() => {
    const checkOrientation = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width > height && isDeviceMobile) {
        setIsTabletLandscape(true);
      } else {
        setIsTabletLandscape(false);
      }
    };

    // Add the event listener
    window.addEventListener("resize", checkOrientation);

    // Call the function once to check the initial orientation
    checkOrientation();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRoomSelect = (room: string) => {
    setSelectedRoom(room);
  };

  useEffect(() => {
    addBreadcrumb(Pages.HousePlanPage);
  }, []);
  return (
    <div className={isVRMode ? styles.noBackground : styles.backgroundImage}>
      {isVRMode ? (
        <iframe
          src="https://etherial.fr/bureau3d/"
          className={styles.backgroundIframe}
          title="VR Room"
        />
      ) : null}
      <div className={styles.container}>
        {!isVRMode ? <Captions /> : null}

        {isVRMode ? <RoomTab onRoomSelect={handleRoomSelect} /> : null}
        <FloorTab />
        <AnimatePresence>
          {activeButton === "PropertyInformations" && (
            <motion.div
              variants={slideVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
              className={styles.animatedContainer}
              style={{
                right: currentLanguage !== "ar" ? 0 : "unset",
                left: currentLanguage === "ar" ? 0 : "unset",
              }}
            >
              <PropertyInformations />
            </motion.div>
          )}
        </AnimatePresence>
        {activeButton === "PropertyInformations" && (
          <MobileTabletPropertyInformations />
        )}
        <PropertyInformationsButton />
        {isDeviceMobile && isTabletLandscape ? (
          <HorizontalTabletHousePlanOptions />
        ) : null}

        <NavigationTab locationName={t(`locations.unit`)} />
        <Compass position={compassPosition} />
      </div>
    </div>
  );
};

export default HousePlanPage;
