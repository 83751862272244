interface Position {
  top: string;
  left: string;
}

interface AmenityPositions {
  [key: string]: Position;
}

interface AmenityCardProps {
  key: string;
  iconType: string;
  position: Position;
}

export const communityPageAmenityPositions: AmenityPositions = {
  hospital: { top: "20%", left: "30%" },
  park: { top: "28%", left: "-15%" },
  retail: { top: "35%", left: "20%" },
  pocketPark: { top: "30%", left: "50%" },
  mosque: { top: "30%", left: "60%" },
  school: { top: "1%", left: "12%" },
};

export const residencyPageAmenityPositions: AmenityPositions = {
  hospital: { top: "2%", left: "12%" },
  park: { top: "20%", left: "70%" },
  retail: { top: "19%", left: "10%" },
  pocketPark: { top: "25%", left: "35%" },
  mosque: { top: "20%", left: "60%" },
  school: { top: "35%", left: "65%" },
};

export function getAmenityCards(
  activeAmenitiesButtons: { [key: string]: boolean },
  amenityPositions: AmenityPositions
): AmenityCardProps[] {
  return Object.keys(activeAmenitiesButtons)
    .filter((key) => activeAmenitiesButtons[key])
    .map((key) => ({
      key,
      iconType: key,
      position: amenityPositions[key] || { top: "0%", left: "0%" },
    }));
}
