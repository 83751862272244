import GlobePage from "./pages/GlobePage/GlobePage";
import CountryPage from "./pages/CountryPage/CountryPage";
import { useContext } from "react";
import { PageContext } from "./contexts";
import CityPage from "./pages/CityPage/CityPage";
import CommunityPage from "./pages/CommunityPage/CommunityPage";
import ResidencyPage from "./pages/ResidencyPage/ResidencyPage";
import HousePlanPage from "./pages/HousePlanPage/HousePlanPage";
import HomePage from "./pages/HomePage/HomePage";
import { AnimatePresence, motion } from "framer-motion";
import TopBar from "./components/Shared/TopBar/TopBar";
import { useTranslation } from "react-i18next";
import MobileTabletShareCard from "./components/Shared/MobileTabletShareCard/MobileTabletShareCard";
import ShareCard from "./components/Shared/ShareCard/ShareCard";
import Callback from "./components/Shared/Callback/Callback";
import MobileTabletCallback from "./components/Shared/MobileTabletCallback/MobileTabletCallback";
import Help from "./components/Shared/Help/Help";
import MobileTabletHelp from "./components/Shared/MobileTabletHelp/MobileTabletHelp";
import { isMobile } from "react-device-detect";
import CopyrightText from "./components/Shared/CopyrightText/CopyrightText";
import MobileMenu from "./components/Shared/MobileMenu/MobileMenu";
import Register from "./components/Shared/Register/Register";
import MobileTabletRegister from "./components/Shared/MobileTabletRegister/MobileTabletRegister";

function App() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const pageContext = useContext(PageContext);
  const { currentPage, isVRMode, activeButton, isBurgerMenuOpen } =
    useContext(PageContext);

  if (!pageContext) {
    return <div>Error: Page context not found</div>;
  }

  const slideVariants = {
    hidden: {
      opacity: 0,
      x: currentLanguage === "ar" ? "-100%" : "100%",
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: currentLanguage === "ar" ? "-100%" : "100%",
    },
  };

  const slideUpVariants = {
    hidden: {
      opacity: 0,
      y: "100%",
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: "100%",
    },
  };

  const renderPage = () => {
    switch (currentPage) {
      case "HomePage":
        return <HomePage />;
      case "GlobePage":
        return <GlobePage />;
      case "CountryPage":
        return <CountryPage />;
      case "CityPage":
        return <CityPage />;
      case "CommunityPage":
        return <CommunityPage />;
      case "ResidencyPage":
        return <ResidencyPage />;
      case "HousePlanPage":
        return <HousePlanPage />;
      default:
        return <div>Unknown Page</div>;
    }
  };
  return (
    <>
      <TopBar isVRMode={isVRMode} />
      <AnimatePresence>
        {activeButton === t(`nav.share`) && (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              bottom: "13%",
              left: "50%",
              zIndex: 3000,
            }}
          >
            <ShareCard />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {activeButton === t(`nav.share`) && isMobile ? (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              bottom: "0%",
              width: "100%",
              height: "100%",
              zIndex: 3000,
            }}
          >
            <MobileTabletShareCard />
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {activeButton === t(`nav.callback`) && (
          <motion.div
            variants={slideVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              zIndex: 3000,
              width: "20%",
              right: currentLanguage !== "ar" ? 0 : "unset",
              left: currentLanguage === "ar" ? 0 : "unset",
            }}
          >
            <Callback />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {activeButton === t(`nav.callback`) && isMobile ? (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              bottom: "0%",
              width: "100%",
              height: "100%",
              zIndex: 3000,
            }}
          >
            <MobileTabletCallback />
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {activeButton === "register" && (
          <motion.div
            variants={slideVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              zIndex: 3000,
              width: "20%",
              right: currentLanguage !== "ar" ? 0 : "unset",
              left: currentLanguage === "ar" ? 0 : "unset",
            }}
          >
            <Register />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {activeButton === "register" && isMobile ? (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              bottom: "0%",
              width: "100%",
              height: "100%",
              zIndex: 3000,
            }}
          >
            <MobileTabletRegister />
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {activeButton === t(`nav.help`) ? (
          <motion.div
            variants={slideVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              zIndex: 3000,
              width: "20%",
              right: currentLanguage !== "ar" ? 0 : "unset",
              left: currentLanguage === "ar" ? 0 : "unset",
              display: isMobile ? "none" : "block",
            }}
          >
            <Help />
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {activeButton === t(`nav.help`) && isMobile ? (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              bottom: "0%",
              width: "100%",
              height: "100%",
              zIndex: 3000,
            }}
          >
            <MobileTabletHelp />
          </motion.div>
        ) : null}
      </AnimatePresence>
      {!isMobile ? <CopyrightText /> : null}
      <AnimatePresence>
        {isBurgerMenuOpen ? (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000,
            }}
          >
            <MobileMenu />
          </motion.div>
        ) : null}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentPage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {renderPage()}
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default App;
