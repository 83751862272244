import { useTranslation } from "react-i18next";
import styles from "./DocumentPage.module.css";
import { DownloadIcon, ViewIcon } from "../../icons/icons";
import { CSSProperties } from "react";

const DocumentPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const wrapperStyle: CSSProperties = {
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
    left: currentLanguage === "ar" ? "unset" : "4%",
    right: currentLanguage === "ar" ? "4%" : "unset",
  };

  const directionStyle: CSSProperties = {
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
  };
  return (
    <div className={styles.container}>
      <span className={styles.title}>{t(`documentsPage.title`)}</span>
      <div className={styles.documentsWrapper} style={directionStyle}>
        <div className={styles.documentWrapper}>
          <img
            className={styles.documentThumbnail}
            src="/assets/images/doc1.jpg"
          />
          <div className={styles.wrapper} style={wrapperStyle}>
            <div className={styles.documentTitle}>
              {t(`documentsPage.documentTitle1`)}
            </div>
            <button className={styles.downloadButton}>
              <DownloadIcon />
              <span>{t(`documentsPage.download`)}</span>
            </button>
          </div>
        </div>
        <div className={styles.documentWrapper}>
          <img
            className={styles.documentThumbnail}
            src="/assets/images/doc2.jpg"
          />
          <div className={styles.wrapper} style={wrapperStyle}>
            <div className={styles.documentTitle}>
              {t(`documentsPage.documentTitle1`)}
            </div>

            <button className={styles.downloadButton}>
              <DownloadIcon />
              <span>{t(`documentsPage.download`)}</span>
            </button>
          </div>
        </div>
        <div className={styles.documentWrapper}>
          <img
            className={styles.documentThumbnail}
            src="/assets/images/doc3.jpg"
          />
          <div className={styles.wrapper} style={wrapperStyle}>
            <div className={styles.documentTitle}>
              {t(`documentsPage.documentTitle1`)}
            </div>

            <button className={styles.downloadButton}>
              <DownloadIcon />
              <span>{t(`documentsPage.download`)}</span>
            </button>
          </div>
        </div>
        <div className={styles.documentWrapper}>
          <img
            className={styles.documentThumbnail}
            src="/assets/images/doc4.jpg"
          />
          <div className={styles.wrapper} style={wrapperStyle}>
            <div className={styles.documentTitle}>
              {t(`documentsPage.documentTitle1`)}
            </div>

            <button className={styles.downloadButton}>
              <DownloadIcon />
              <span>{t(`documentsPage.download`)}</span>
            </button>
          </div>
        </div>
        <div className={styles.documentWrapper}>
          <img
            className={styles.documentThumbnail}
            src="/assets/images/doc5.jpg"
          />
          <div className={styles.wrapper} style={wrapperStyle}>
            <div className={styles.documentTitle}>
              {t(`documentsPage.documentTitle1`)}
            </div>

            <button className={styles.downloadButton}>
              <DownloadIcon />
              <span>{t(`documentsPage.download`)}</span>
            </button>
          </div>
        </div>
        <div className={styles.documentWrapper}>
          <img
            className={styles.documentThumbnail}
            src="/assets/images/doc6.jpg"
          />
          <div className={styles.wrapper} style={wrapperStyle}>
            <div className={styles.documentTitle}>
              {t(`documentsPage.documentTitle1`)}
            </div>

            <button className={styles.downloadButton}>
              <DownloadIcon />
              <span>{t(`documentsPage.download`)}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPage;
