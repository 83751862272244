import { useContext, useEffect } from "react";
import MapLocation from "../../components/Shared/MapLocation/MapLocation";
import NavigationTab from "../../components/Shared/NavigationTab/NavigationTab";
import { PageContext, Pages } from "../../contexts";
import styles from "./CommunityPage.module.css";
import ZoomToolbar from "../../components/Shared/ZoomToolbar/ZoomToolbar";
import HorizontalTaletMobileFilters from "../../components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters";
import { useTranslation } from "react-i18next";
import Compass, { Position } from "../../components/Shared/Compass/Compass";
import AmenitieCard from "../../components/Shared/AmenitieCard/AmenitieCard";
import {
  communityPageAmenityPositions,
  getAmenityCards,
} from "../../utils/amenityCardPositions";

const CommunityPage: React.FC = () => {
  const { t } = useTranslation();
  const { addBreadcrumb, activeAmenitiesButtons } = useContext(PageContext);

  const compassPosition: Position = {
    mobile: {
      en: {
        right: "3%",
        top: "10%",
      },
      ar: {
        left: "3%",
        top: "10%",
      },
    },
    desktop: {
      en: {
        bottom: "5%",
        left: "2%",
      },
      ar: {
        bottom: "5%",
        right: "2%",
      },
    },
  };

  const amenityCards = getAmenityCards(
    activeAmenitiesButtons,
    communityPageAmenityPositions
  );

  useEffect(() => {
    addBreadcrumb(Pages.CommunityPage);
  }, []);

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };

    // Initial set
    setBodyHeight();

    // Update on resize
    window.addEventListener("resize", setBodyHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", setBodyHeight);
    };
  }, []);
  return (
    <div className={styles.backgroundImage}>
      <div className={styles.container}>
        {amenityCards.map((amenity) => (
          <AmenitieCard
            key={amenity.key}
            iconType={amenity.iconType}
            top={amenity.position.top}
            left={amenity.position.left}
          />
        ))}
        <ZoomToolbar />
        <MapLocation
          targetPage={Pages.ResidencyPage}
          locationName={t(`locations.residency`)}
          position={{
            mobile: { top: "30%", left: "20%" },
            desktop: { top: "40%", left: "50%" },
          }}
        />
        <NavigationTab locationName={t(`locations.community`)} />
        <HorizontalTaletMobileFilters />
        <Compass position={compassPosition} />
      </div>
    </div>
  );
};

export default CommunityPage;
