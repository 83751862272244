import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { PageContext } from "../../../contexts";
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  WhatsappIcon,
  TelegramIcon,
  LinkLockIcon,
  CloseIcon,
  LargeLinkLockIcon,
} from "../../../icons/icons";
import styles from "./ShareCard.module.css";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const ShareCard: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { setActiveButton } = useContext(PageContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [borderColor, setBorderColor] = useState("#3e3d44");
  const [isFocused, setIsFocused] = useState(false);
  const [tooltipText, setTooltipText] = useState(t(`share.clickToCopy`));
  const [showTooltip, setShowTooltip] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const copyToClipboard = () => {
    if (inputRef.current !== null) {
      navigator.clipboard.writeText(inputRef.current.value);
      setBorderColor("#d27bfa");
      setTooltipText(t(`share.copied`));
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleMouseOver = () => {
    const selection = window.getSelection();
    if (selection && inputRef.current) {
      if (selection.toString() === inputRef.current.value) {
        setIsFocused(true);
      }
    }
  };

  // Functions to handle tooltip
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    setTooltipText(t(`share.clickToCopy`)); // Reset tooltip text
  };

  useEffect(() => {
    const resetBorderColor = () => setBorderColor("#3e3d44"); // Reset to default color
    document.addEventListener("mousedown", resetBorderColor);
    return () => {
      document.removeEventListener("mousedown", resetBorderColor);
    };
  }, []);

  const containerStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
  };

  const inputStyle: CSSProperties = isFocused ? { whiteSpace: "nowrap" } : {};

  const handleClose = () => {
    setActiveButton(null); // Reset the activeButton state
  };

  if (isMobile) {
    return null;
  }
  return (
    <div className={styles.container} style={containerStyle} ref={containerRef}>
      <div className={styles.header}>
        <div>{t(`nav.share`)}</div>
        <button
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={styles.closeButton}
          onClick={handleClose}
        >
          <CloseIcon color={isHovered ? "#000" : "#fff"} />
        </button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.columnWrapper}>
          <div className={styles.wrapperTitle}>{t(`share.shareLink`)}</div>
          <div className={styles.shareButtonWrapper}>
            <button className={styles.twitterButton}>
              <TwitterIcon />
            </button>
            <button className={styles.facebookButton}>
              <FacebookIcon />
            </button>
            <button className={styles.instagramButton}>
              <InstagramIcon />
            </button>
            <button className={styles.whatsappButton}>
              <WhatsappIcon />
            </button>
            <button className={styles.telegramButton}>
              <TelegramIcon />
            </button>
          </div>
        </div>
        <div className={styles.columnWrapper}>
          <div className={styles.wrapperTitle}>{t(`share.copyLink`)}</div>
          <div
            className={styles.inputWrapper}
            style={{ borderColor: borderColor }}
          >
            <LargeLinkLockIcon />
            <input
              type="text"
              value="http://www.virtualvisions.studio/"
              ref={inputRef}
              className={styles.hyperlink}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={inputStyle}
              onMouseOver={handleMouseOver}
            />
            <div
              className={styles.copyButtonContainer}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {showTooltip && (
                <div className={styles.tooltip}>{tooltipText}</div>
              )}
              <button className={styles.copyButton} onClick={copyToClipboard}>
                {t(`share.copy`)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareCard;
