import styles from "./CountryPage.module.css";
import NavigationTab from "../../components/Shared/NavigationTab/NavigationTab";
import MapLocation from "../../components/Shared/MapLocation/MapLocation";
import { PageContext, Pages } from "../../contexts";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Compass, { Position } from "../../components/Shared/Compass/Compass";

export default function CountryPage() {
  const { t } = useTranslation();
  const { addBreadcrumb } = useContext(PageContext);

  const compassPosition: Position = {
    mobile: {
      en: {
        right: "3%",
        top: "2%",
      },
      ar: {
        left: "3%",
        top: "2%",
      },
    },
    desktop: {
      en: {
        bottom: "5%",
        left: "2%",
      },
      ar: {
        bottom: "5%",
        right: "2%",
      },
    },
  };

  useEffect(() => {
    addBreadcrumb(Pages.CountryPage); // Add the breadcrumb for the country page
  }, []);

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };

    // Initial set
    setBodyHeight();

    // Update on resize
    window.addEventListener("resize", setBodyHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", setBodyHeight);
    };
  }, []);

  return (
    <div className={styles.backgroundImage}>
      <div className={styles.container}>
        <MapLocation
          targetPage={Pages.CityPage}
          locationName={t(`locations.city`)}
          position={{
            mobile: { top: "27%", left: "22%" },
            desktop: { top: "29%", left: "43%" },
          }}
        />
        <NavigationTab locationName={t(`locations.saudiArabia`)} />
        <Compass position={compassPosition} />
      </div>
    </div>
  );
}
