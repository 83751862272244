import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import styles from "./MobileFilterServices.module.css";
import { CloseAmenitiesIcon } from "../../../icons/icons";
import MapFiltersButton from "../MapFiltersButton/MapFiltersButton";
import { useTranslation } from "react-i18next";
import { PageContext } from "../../../contexts";

interface Props {
  onClose: () => void;
}

const MobileFilterServices: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const { activeFilterButtons, setActiveFilterButtons } =
    useContext(PageContext);

  useEffect(() => {
    // Function to handle touch events
    const handleTouchStart = (e: TouchEvent) => {
      if (sliderRef.current && !sliderRef.current.contains(e.target as Node)) {
        props.onClose();
      }
    };

    // Add touchstart event listener
    document.addEventListener("touchstart", handleTouchStart);

    // Cleanup
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
    if (currentLanguage === "ar" && sliderRef.current) {
      const sliderElement = sliderRef.current;
      sliderElement.scrollLeft = sliderElement.scrollWidth; // Set the scroll position to the end
    }
  }, [currentLanguage]);

  const toggleButton = (name: string) => {
    setActiveFilterButtons((prevButtons) => ({
      ...prevButtons,
      [name]: !prevButtons[name],
    }));
  };

  const filterButtonNames = [
    "landmarks",
    "residential",
    "retail",
    "health",
    "business",
    "religious",
    "education",
  ];

  const closeButtonStyle: CSSProperties = {
    marginRight: currentLanguage === "ar" ? "unset" : "8px",
    marginLeft: currentLanguage === "ar" ? "8px" : "unset",
  };

  const closeButton = (
    <button
      className={styles.closeButton}
      style={closeButtonStyle}
      onClick={props.onClose}
    >
      <CloseAmenitiesIcon color="#111018" />
    </button>
  );

  const filterButtons = filterButtonNames.map((name, index) => (
    <MapFiltersButton
      key={index}
      name={t(`filters.${name}`)}
      isActive={activeFilterButtons[name]}
      toggleActive={() => toggleButton(name)}
    />
  ));

  const sliderItems = [closeButton, ...filterButtons];

  const displayItems =
    currentLanguage === "ar" ? [...sliderItems].reverse() : sliderItems;

  return (
    <div className={styles.listContainer} ref={sliderRef}>
      <ul className={styles.list}>
        {displayItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default MobileFilterServices;
