import { CSSProperties, useContext } from "react";
import { PageContext } from "../../../contexts";
import {
  DoubleArrowLeftIcon,
  DoubleArrowRighttIcon,
} from "../../../icons/icons";
import styles from "./PropertyInformationsButton.module.css";
import { useTranslation } from "react-i18next";

const PropertyInformationsButton: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { setActiveButton } = useContext(PageContext);

  const containerStyle: CSSProperties = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    right: currentLanguage === "ar" ? "unset" : "2%",
    left: currentLanguage === "ar" ? "2%" : "unset",
  };

  const handleClick = () => {
    setActiveButton("PropertyInformations");
  };
  return (
    <button
      className={styles.container}
      style={containerStyle}
      onClick={handleClick}
    >
      <div className={styles.iconWrapper}>
        {currentLanguage === "ar" ? (
          <DoubleArrowRighttIcon />
        ) : (
          <DoubleArrowLeftIcon />
        )}
      </div>
      <div className={styles.text}>{t(`button.showInformations`)}</div>
    </button>
  );
};

export default PropertyInformationsButton;
