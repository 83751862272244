import { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./AmenitieCard.module.css";
import { useTranslation } from "react-i18next";
import {
  ReligiousIcon,
  HospitalIcon,
  ParkIcon,
  RetailIcon,
  PocketParkIcon,
  EducationIcon,
} from "../../../icons/icons";

type Props = {
  top: string;
  left: string;
  iconType: string;
};

const AmenitieCard: React.FC<Props> = ({ top, left, iconType }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [cardContainerStyle, setCardContainerStyle] = useState<CSSProperties>(
    {}
  );

  useEffect(() => {
    if (containerRef.current) {
      const cardRect = containerRef.current.getBoundingClientRect();
      let transformX = "0%";
      let transformY = "0%";

      // Check if the card overflows the right edge of the viewport
      if (cardRect.right > window.innerWidth) {
        transformX = "-50%"; // Adjust this value as needed
      }

      // Check if the card overflows the left edge of the viewport
      if (cardRect.left < 0) {
        transformX = "50%"; // Adjust this value as needed
      }

      // Check if the card overflows the bottom edge of the viewport
      if (cardRect.bottom > window.innerHeight) {
        transformY = "-100%"; // Adjust this value as needed
      }

      // Apply the new transform
      setCardContainerStyle({
        transform: `translate(${transformX}, ${transformY})`,
      });
    }
  }, [isVisible]);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  function renderIcon(iconType: string) {
    switch (iconType) {
      case "hospital":
        return <HospitalIcon color="#ff6767" />;
      case "park":
        return <ParkIcon color="#95d935" />;
      case "retail":
        return <RetailIcon color="#D27BF9" />;
      case "pocketPark":
        return <PocketParkIcon color="#6780ff" />;
      case "mosque":
        return <ReligiousIcon color="#F8D648" />;
      case "school":
        return <EducationIcon color="#987BFA" />;
      default:
        return null; // or some default icon
    }
  }

  const mainContainerStyle: CSSProperties & { [key: string]: any } = {
    top,
    left,
  };

  const containerStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
    opacity: isVisible ? 1 : 0,
  };
  return (
    <div
      className={styles.container}
      ref={containerRef}
      style={mainContainerStyle}
    >
      <div
        className={styles.cardContainer}
        style={{ ...containerStyle, ...cardContainerStyle }}
      >
        <img className={styles.image} src="/assets/images/mosque.jpg" />
        <div className={styles.wrapper}>
          <div className={styles.title}>{t(`amenitieCard.mosqueTitle`)}</div>
          <div className={styles.description}>
            {t(`amenitieCard.mosqueDescription`)}
          </div>
        </div>
      </div>
      <div className={styles.amenitieInfo}>
        <div
          className={styles.iconWrapper}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {renderIcon(iconType)}
        </div>
        <div className={styles.amenitieName}>{t(`amenities.${iconType}`)}</div>
      </div>
    </div>
  );
};

export default AmenitieCard;
