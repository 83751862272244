import styles from "./NavigationTab.module.css";
import "../../../style/fonts.css";
import BackButton from "../BackButton/BackButton";
import BuergerMenu from "../BuergerMenu/BuergerMenu";
import { CSSProperties, useContext, useEffect, useState } from "react";
import NavigationTabButton from "../NavigationTabButton/NavigationTabButton";
import { PageContext } from "../../../contexts";
import MapFilters from "../MapFilters/MapFilters";
import MobileFilters from "../MobileFilters/MobileFilters";
import Amenities from "../Amenities/Amenities";
import MobileAmenities from "../MobileAmenities/MobileAmenities";
import MobileFilterServices from "../MobileFilterServices/MobileFilterServices";
import HousePlanOptions from "../HousePlanOptions/HousePlanOptions";
import { isMobile as isDeviceMobile } from "react-device-detect";
import HorizontalTabletRoomTab from "../HorizontalTabletRoomTab/HorizontalTabletRoomTab";
import { useTranslation } from "react-i18next";
import React from "react";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  locationName: string;
}

type PageType =
  | "GlobePage"
  | "CityPage"
  | "CommunityPage"
  | "ResidencyPage"
  | "HousePlanPage";

const NavigationTab: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 993);
  const [isTabletLandscape, setIsTabletLandscape] = useState(false);
  const {
    currentPage,
    handleGoBack,
    activeButton,
    selectedFilter,
    setSelectedFilter,
  } = useContext(PageContext) as {
    currentPage: PageType;
    handleGoBack: () => void;
    activeButton: string;
    selectedFilter: string | null;
    setSelectedFilter: (filter: string | null) => void;
  };

  const { setActiveButton, isVRMode } = useContext(PageContext);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const containerStyle: CSSProperties = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    display: isVRMode ? "none" : "flex",
  };

  useEffect(() => {
    const checkOrientation = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width > height && isDeviceMobile) {
        setIsTabletLandscape(true);
      } else {
        setIsTabletLandscape(false);
      }
    };

    // Add the event listener
    window.addEventListener("resize", checkOrientation);

    // Call the function once to check the initial orientation
    checkOrientation();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  let buttonClicked = false;

  const slideUpVariants = {
    hidden: {
      opacity: 0,
      y: "100%",
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: "100%",
    },
  };

  // Handle button click to set active button
  const handleButtonClick = (buttonName: string) => {
    buttonClicked = true; // Set the flag to true
    if (activeButton === buttonName) {
      setActiveButton(null);
    } else {
      setActiveButton(buttonName);
    }
  };

  // Close mobile amenities and reset selected filter
  const handleCloseMobileAmenities = () => {
    setSelectedFilter(null);
  };

  // Listener to handle window resize and set mobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Reset the selected filter when switching to desktop
  useEffect(() => {
    if (!isMobile) {
      setSelectedFilter(null);
    }
  }, [isMobile]);

  // Configuration for buttons specific to each page type
  const buttonsConfig: Record<PageType, string[]> = {
    GlobePage: [],
    CityPage: [t(`nav.filters`), t(`nav.radius`)],
    CommunityPage: [t(`nav.amenities`)],
    ResidencyPage: [t(`nav.filters`), t(`nav.amenities`)],
    HousePlanPage: [],
  };

  // Common buttons that appear on all pages
  const commonButtons = [
    t(`nav.share`),
    t(`nav.language`),
    t(`nav.callback`),
    t(`nav.help`),
  ];

  // Render navigation buttons based on the current page and common buttons
  const renderNavigationButtons = () => {
    const pageSpecificButtons = buttonsConfig[currentPage] || [];
    const allButtons = [...pageSpecificButtons, ...commonButtons];

    return allButtons.map((buttonName, index) => (
      <React.Fragment key={buttonName}>
        <NavigationTabButton
          key={buttonName}
          name={buttonName}
          onClick={() => handleButtonClick(buttonName)}
          isActive={activeButton === buttonName}
        />
        {/* Insert separator after "Language" button */}
        {buttonName === t(`nav.language`) && (
          <div className={styles.separator}></div>
        )}
      </React.Fragment>
    ));
  };

  // Render mobile-specific content based on the current page
  const renderMobileContent = () => {
    if (currentPage === "GlobePage") {
      return (
        <>
          <div style={{ width: "35px" }}></div>
          <div className={styles.locationName}>{props.locationName}</div>
          <BuergerMenu />
        </>
      );
    }
    return (
      <>
        <BackButton goBack={handleGoBack} />
        <div className={styles.locationName}>{props.locationName}</div>
        <BuergerMenu />
      </>
    );
  };

  return (
    <div className={styles.mainWrapper}>
      {/* Render MapFilters if conditions are met */}
      <AnimatePresence>
        {activeButton === t(`nav.filters`) &&
          currentPage !== "ResidencyPage" && (
            <motion.div
              variants={slideUpVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
            >
              <MapFilters />
            </motion.div>
          )}
      </AnimatePresence>

      {/* Render Amenities for non-mobile and if activeButton is "Amenities" */}
      <AnimatePresence>
        {activeButton === t(`nav.amenities`) && !isMobile && (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            <Amenities />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Render mobile-specific components based on selectedFilter and currentPage */}
      {(currentPage === "CityPage" ||
        currentPage === "CommunityPage" ||
        currentPage === "ResidencyPage" ||
        currentPage === "HousePlanPage") && (
        <>
          {currentPage === "HousePlanPage" ? (
            isDeviceMobile && isTabletLandscape ? (
              <HorizontalTabletRoomTab />
            ) : (
              <HousePlanOptions onSelectOption={setSelectedOption} />
            )
          ) : (
            <>
              {!isTabletLandscape && selectedFilter === "MobileAmenities" && (
                <MobileAmenities onClose={handleCloseMobileAmenities} />
              )}
              {!isTabletLandscape && selectedFilter === "MobileFilters" && (
                <MobileFilterServices onClose={handleCloseMobileAmenities} />
              )}
              {selectedFilter !== "MobileAmenities" &&
                selectedFilter !== "MobileFilters" && (
                  <MobileFilters
                    currentPage={currentPage}
                    selectedFilter={selectedFilter}
                    onSelectFilter={setSelectedFilter}
                    setActiveButton={setActiveButton}
                    activeButton={activeButton}
                  />
                )}
            </>
          )}
        </>
      )}

      <div className={styles.container} style={containerStyle}>
        {isMobile || isTabletLandscape
          ? renderMobileContent()
          : renderNavigationButtons()}
      </div>
    </div>
  );
};

export default NavigationTab;
