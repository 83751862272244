import React from "react";

export enum Pages {
  HomePage = "HomePage",
  GlobePage = "GlobePage",
  CountryPage = "CountryPage",
  CityPage = "CityPage",
  CommunityPage = "CommunityPage",
  ResidencyPage = "ResidencyPage",
  HousePlanPage = "HousePlanPage",
}

interface PageContextProps {
  currentPage: Pages;
  breadcrumb: string[];
  navigateToPage: (page: Pages) => void;
  goBack: () => void;
  addBreadcrumb: (page: Pages) => void;
  handleGoBack: () => void;
  activeButton: string | null;
  setActiveButton: (buttonName: string | null) => void;
  isBurgerMenuOpen: boolean;
  toggleBurgerMenu: () => void;
  isUnitInformationsOpen: boolean;
  toggleUnitInformations: () => void;
  isVRMode: boolean;
  setIsVRMode: React.Dispatch<React.SetStateAction<boolean>>;
  toggleVRMode: () => void;
  selectedRoom: string;
  setSelectedRoom: (room: string) => void;
  selectedFilter: string | null;
  setSelectedFilter: (filter: string | null) => void;
  areCaptionsOn: boolean;
  toggleCaptions: (state: boolean) => void;
  navigateToBreadcrumb?: (pageName: string) => void;
  activeAmenitiesButtons: { [key: string]: boolean };
  setActiveAmenitiesButtons: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  activeFilterButtons: { [key: string]: boolean };
  setActiveFilterButtons: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultPageContextValue: PageContextProps = {
  currentPage: Pages.HomePage,
  breadcrumb: ["Earth"],
  navigateToPage: () => {},
  goBack: () => {},
  addBreadcrumb: () => {},
  handleGoBack: () => {},
  activeButton: null,
  setActiveButton: () => {},
  isBurgerMenuOpen: false,
  toggleBurgerMenu: () => {},
  isUnitInformationsOpen: false,
  toggleUnitInformations: () => {},
  isVRMode: false,
  toggleVRMode: () => {},
  setIsVRMode: () => {},
  selectedRoom: "Kitchen",
  setSelectedRoom: () => {},
  selectedFilter: null,
  setSelectedFilter: () => {},
  areCaptionsOn: true,
  toggleCaptions: () => {},
  activeAmenitiesButtons: {
    hospital: false,
    park: false,
    retail: false,
    pocketPark: false,
    mosque: false,
    school: false,
  },
  setActiveAmenitiesButtons: () => {},
  activeFilterButtons: {
    landmarks: false,
    residential: false,
    retail: false,
    health: false,
    business: false,
    religious: false,
    education: false,
  },
  setActiveFilterButtons: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
};

export const PageContext = React.createContext(defaultPageContextValue);
