import styles from "./MobileTabletCallback.module.css";
import { CSSProperties, useContext } from "react";
import { PageContext } from "../../../contexts";
import { CloseIcon } from "../../../icons/icons";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import MobileTabletCallbackForm from "../../Form/MobileTabletCallbackForm/MobileTabletCallbackForm";

const MobileTabletCallback: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { activeButton, setActiveButton } = useContext(PageContext);

  const containerStyle: CSSProperties = {
    direction: currentLanguage === "ar" ? "rtl" : "ltr",
  };

  const closeButtonStyle: CSSProperties = {
    right: currentLanguage === "ar" ? "unset" : "3%",
    left: currentLanguage === "ar" ? "3%" : "unset",
  };

  const handleClose = () => {
    setActiveButton(null); // Reset the activeButton state
  };

  const handleCallHelp = () => {
    setActiveButton(t(`nav.help`)); // Reset the activeButton state
  };

  if (!isMobile) {
    return null;
  }

  if (activeButton !== t(`nav.callback`)) return null;

  return (
    <div className={styles.container} style={containerStyle}>
      <button
        className={styles.closeButton}
        style={closeButtonStyle}
        onClick={handleClose}
      >
        <CloseIcon color="#fff" />
      </button>
      <div className={styles.header}>
        <span className={styles.title}>{t(`callback.request`)}</span>
      </div>
      <p className={styles.description}>{t(`callback.description`)}</p>
      <MobileTabletCallbackForm />
      <div className={styles.bottomText}>{t(`callback.question`)}</div>
      <div style={{ textAlign: "center" }}>
        <div className={styles.helpWrapper}>
          {t(`callback.check`)}{" "}
          <div className={styles.help} onClick={handleCallHelp}>
            {t(`callback.help`)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileTabletCallback;
