import styles from "./MobileTabletHelp.module.css";
import { CSSProperties, useContext, useState } from "react";
import { PageContext } from "../../../contexts";
import { CloseIcon, HelpIcon } from "../../../icons/icons";
import FAQItem from "../Help/FAQItem/FAQItem";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const MobileTabletHelp: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { activeButton, setActiveButton } = useContext(PageContext);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);

  const closeButtonStyle: CSSProperties = {
    right: currentLanguage === "ar" ? "unset" : "3%", // unset left when language is "ar"
    left: currentLanguage === "ar" ? "3%" : "unset", // set right to 2% when language is "ar"
  };

  const headerStyle: CSSProperties = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
  };

  const descriptionStyle: CSSProperties = {
    textAlign: currentLanguage === "ar" ? "right" : "left",
  };

  const handleClose = () => {
    setActiveButton(null); // Reset the activeButton state
  };

  if (!isMobile) {
    return null;
  }

  const faqData = [
    {
      question: t(`help.changePlan`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.trialAvailable`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.cancellationPolicy`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.addInfo`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.getInvoice`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.affordableVersion`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.changeAccount`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.platformSecure`),
      answer: t(`help.trialAvailableResponse`),
    },
  ];

  if (activeButton !== t(`nav.help`)) return null;

  return (
    <div className={styles.container}>
      <button
        className={styles.closeButton}
        style={closeButtonStyle}
        onClick={handleClose}
      >
        <CloseIcon color="#fff" />
      </button>
      <div className={styles.header} style={headerStyle}>
        <span className={styles.title}>{t(`help.helpServices`)}</span>
      </div>
      <p className={styles.description} style={descriptionStyle}>
        {t(`help.description`)}
      </p>
      <div className={styles.faq}>
        {faqData.map((item, index) => (
          <FAQItem
            key={index}
            index={index}
            question={item.question}
            answer={item.answer}
            expanded={expandedIndex === index}
            setExpandedIndex={setExpandedIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileTabletHelp;
