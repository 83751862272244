import React from "react";
import styles from "./NavigationTabButton.module.css";
import {
  ShareIcon,
  LanguageIcon,
  CallbackIcon,
  HelpIcon,
  FiltersIcon,
  RadiusIcon,
  AmenetiesIcon,
} from "../../../icons/icons";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  onClick: () => void;
  isActive: boolean;
}

const NavigationTabButton: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();

  const handleLanguageClick = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en"; // Toggle between English and Arabic
    i18n.changeLanguage(newLanguage);
  };

  const renderIcon = () => {
    switch (props.name) {
      case t(`nav.share`):
        return <ShareIcon />;
      case t(`nav.language`):
        return <LanguageIcon />;
      case t(`nav.callback`):
        return <CallbackIcon color="#fff" />;
      case t(`nav.help`):
        return <HelpIcon color="#fff" />;
      case t(`nav.filters`):
        return <FiltersIcon />;
      case t(`nav.radius`):
        return <RadiusIcon />;
      case t(`nav.amenities`):
        return <AmenetiesIcon />;
    }
  };

  const handleClick = () => {
    if (props.name === t(`nav.language`)) {
      handleLanguageClick();
    } else {
      props.onClick();
    }
  };

  return (
    <button
      className={`${styles.container} ${props.isActive ? styles.active : ""}`}
      onClick={handleClick}
    >
      {renderIcon()}
      <span
        className={`${styles.text} ${props.isActive ? styles.activeText : ""}`}
      >
        {props.name}
      </span>
    </button>
  );
};

export default NavigationTabButton;
