import React, { useState } from "react";
import styles from "./ImageCarousel.module.css";
import { ArrowleftIcon, ArrowrightIcon } from "../../../icons/icons";

interface ImageCarouselProps {
  images: string[]; // Array of image URLs
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHoveredLeft, setIsHoveredLeft] = useState(false);
  const [isHoveredRight, setIsHoveredLeftRight] = useState(false);

  const handleScroll = (direction: "left" | "right") => {
    const newIndex =
      direction === "left"
        ? Math.max(currentIndex - 1, 0)
        : Math.min(currentIndex + 1, images.length - 1);

    setCurrentIndex(newIndex);
  };

  return (
    <div className={styles.carouselContainer}>
      <button
        onMouseEnter={() => setIsHoveredLeft(true)}
        onMouseLeave={() => setIsHoveredLeft(false)}
        className={`${styles.carouselButton} ${styles.leftButton}`}
        onClick={() => handleScroll("left")}
      >
        <ArrowleftIcon color={isHoveredLeft ? "#111018" : "#fff"} />
      </button>
      <img
        className={styles.carouselImage}
        src={images[currentIndex]}
        alt={`carousel-${currentIndex}`}
      />
      <button
        onMouseEnter={() => setIsHoveredLeftRight(true)}
        onMouseLeave={() => setIsHoveredLeftRight(false)}
        className={`${styles.carouselButton} ${styles.rightButton}`}
        onClick={() => handleScroll("right")}
      >
        <ArrowrightIcon color={isHoveredRight ? "#111018" : "#fff"} />
      </button>
    </div>
  );
};

export default ImageCarousel;
