import React from "react";
import styles from "./BackButton.module.css";
import { BackIcon, InvertedBackIcon } from "../../../icons/icons";
import { useTranslation } from "react-i18next";

interface BackButtonProps {
  goBack: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ goBack }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const ArrowIcon = currentLanguage === "ar" ? InvertedBackIcon : BackIcon;
  return (
    <button className={styles.container} onClick={goBack}>
      <ArrowIcon />
      <span className={styles.text}>{t(`button.back`)}</span>
    </button>
  );
};

export default BackButton;
