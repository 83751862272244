import { CSSProperties } from "react";
import { AmenetiesIcon, FiltersIcon, RadiusIcon } from "../../../icons/icons";
import styles from "./MobileFilters.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  currentPage: string;
  selectedFilter: string | null;
  onSelectFilter: (filter: string | null) => void;
  setActiveButton: (buttonName: string) => void;
  activeButton: string;
}

const MobileFilters: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const {
    onSelectFilter,
    currentPage,
    setActiveButton,
    activeButton,
    selectedFilter,
  } = props;

  const handleFilterClick = () => {
    if (currentPage === "ResidencyPage") {
      setActiveButton(t(`nav.filters`));
      if (activeButton === t(`nav.filters`)) {
        setActiveButton("");
      }
    } else {
      onSelectFilter("MobileFilters");
    }
  };

  const handleAmenitiesClick = () => {
    onSelectFilter("MobileAmenities");
    console.log(selectedFilter);
  };

  const handleRadiusClick = () => {
    if (activeButton === t(`nav.radius`)) {
      setActiveButton("");
    } else {
      setActiveButton(t(`nav.radius`));
    }
  };

  const wrapperStyle: CSSProperties = {
    justifyContent: currentLanguage === "ar" ? "flex-end" : "flex-start",
  };

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      {currentPage === "CommunityPage" ? null : (
        <button className={styles.filter} onClick={handleFilterClick}>
          <FiltersIcon />
        </button>
      )}
      {currentPage === "ResidencyPage" ||
      currentPage === "CommunityPage" ? null : (
        <button className={styles.filter} onClick={handleRadiusClick}>
          <RadiusIcon />
        </button>
      )}
      <button className={styles.filter} onClick={handleAmenitiesClick}>
        <AmenetiesIcon />
      </button>
    </div>
  );
};

export default MobileFilters;
