import React, { CSSProperties, useState } from "react";
import styles from "./FAQItem.module.css";
import { useTranslation } from "react-i18next";
import { FAQArrowDownIcon, FAQArrowUpIcon } from "../../../../icons/icons";

interface FAQItemProps {
  question: string;
  answer: string;
  index: number;
  expanded: boolean;
  setExpandedIndex: (index: number | null) => void;
}

const FAQItem: React.FC<FAQItemProps> = ({
  question,
  answer,
  index,
  expanded,
  setExpandedIndex,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const questionStyle: CSSProperties = {
    textAlign: currentLanguage === "ar" ? "right" : "left",
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
  };

  const answerStyle: CSSProperties = {
    textAlign: currentLanguage === "ar" ? "right" : "left",
  };

  const toggleExpand = () => {
    if (expanded) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div
      className={expanded ? styles.containerExpanded : styles.container}
      onClick={toggleExpand}
    >
      <div
        className={expanded ? styles.questionExpanded : styles.question}
        style={questionStyle}
      >
        {question}
        {expanded ? <FAQArrowUpIcon /> : <FAQArrowDownIcon />}
      </div>
      {expanded && (
        <div className={styles.answer} style={answerStyle}>
          {answer}
        </div>
      )}
    </div>
  );
};

export default FAQItem;
