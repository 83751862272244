import { CSSProperties, useState } from "react";
import ZoomToolbarButton from "../ZoomToolbarButton/ZoomToolbarButton";
import styles from "./ZoomToolbar.module.css";
import { useTranslation } from "react-i18next";

const ZoomToolbar: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [selectedButton, setSelectedButton] = useState<string | null>(null);

  const containerStyle: CSSProperties = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    right: currentLanguage === "ar" ? "unset" : "2%",
    left: currentLanguage === "ar" ? "2%" : "unset",
  };

  const handleButtonClick = (name: string) => {
    setSelectedButton(name);
  };

  return (
    <div className={styles.container} style={containerStyle}>
      <ZoomToolbarButton
        name="unzoom"
        isSelected={selectedButton === "unzoom"}
        onClick={() => handleButtonClick("unzoom")}
      />
      <ZoomToolbarButton
        name="zoom"
        isSelected={selectedButton === "zoom"}
        onClick={() => handleButtonClick("zoom")}
      />
      <ZoomToolbarButton
        name="frame"
        isSelected={selectedButton === "frame"}
        onClick={() => handleButtonClick("frame")}
      />
    </div>
  );
};

export default ZoomToolbar;
