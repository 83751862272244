import { MuseumIcon, AirportIcon } from "../../../icons/icons";
import styles from "./LocationPin.module.css";

interface Position {
  mobile: { left: string; top: string };
  tablet: { left: string; top: string };
  desktop: { left: string; top: string };
}

interface Props {
  type: string;
  name: string;
  position: Position;
}

const LocationPin: React.FC<Props> = ({ type, name, position }) => {
  const style = {
    left: position.mobile.left, // For mobile
    top: position.mobile.top, // For mobile
    // Add media queries for tablet and desktop
    "@media (minWidth: 768px)": {
      left: position.tablet.left, // For tablet
      top: position.tablet.top, // For tablet
    },
    "@media (minWidth: 1024px)": {
      left: position.desktop.left, // For desktop
      top: position.desktop.top, // For desktop
    },
  };
  return (
    <div className={styles.wrapper} style={style}>
      <div className={styles.container}>
        {type === "Museum" ? <MuseumIcon /> : <AirportIcon />}
        <span className={styles.locationName}>{name}</span>
        <div className={styles.pointer}>
          <div className={styles.outerCircle}>
            <div className={styles.innerCircle}>
              <div className={styles.line} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationPin;
