import React, { useContext, useRef } from "react";
import AmenitiesButton from "../AmenitiesButton/AmenitiesButton";
import styles from "./Amenities.module.css";
import { useTranslation } from "react-i18next";
import { PageContext } from "../../../contexts";

const Amenities: React.FC = () => {
  const { t } = useTranslation();
  const { activeAmenitiesButtons, setActiveAmenitiesButtons } =
    useContext(PageContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const toggleButton = (name: string) => {
    setActiveAmenitiesButtons((prevButtons) => ({
      ...prevButtons,
      [name]: !prevButtons[name],
    }));
  };

  const amenitiesButtonNames = [
    "hospital",
    "park",
    "retail",
    "pocketPark",
    "mosque",
    "school",
  ];

  return (
    <div className={styles.container} ref={containerRef}>
      {amenitiesButtonNames.map((name, index) => (
        <AmenitiesButton
          key={index}
          name={t(`amenities.${name}`)}
          isActive={activeAmenitiesButtons[name]}
          toggleActive={() => toggleButton(name)}
        />
      ))}
    </div>
  );
};

export default Amenities;
