import { useContext, useEffect, useState } from "react";
import NavigationTab from "../../components/Shared/NavigationTab/NavigationTab";
import ZoomToolbar from "../../components/Shared/ZoomToolbar/ZoomToolbar";
import { PageContext, Pages } from "../../contexts";
import styles from "./ResidencyPage.module.css";
import PropertyFilters from "../../components/Shared/PropertyFilters/PropertyFilters";
import HorizontalTaletMobileFilters from "../../components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters";
import { useTranslation } from "react-i18next";
import Compass, { Position } from "../../components/Shared/Compass/Compass";
import UnitInformations from "../../components/Shared/UnitInformations/UnitInformations";
import MobileUnitInformations from "../../components/Shared/MobileUnitInformations/MobileUnitInformations";
import TabletUnitInformations from "../../components/Shared/TabletUnitInformations/TabletUnitInformations";
import AmenitieCard from "../../components/Shared/AmenitieCard/AmenitieCard";
import { AnimatePresence, motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import {
  residencyPageAmenityPositions,
  getAmenityCards,
} from "../../utils/amenityCardPositions";

const ResidencyPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const {
    addBreadcrumb,
    activeButton,
    currentPage,
    activeAmenitiesButtons,
    setActiveButton,
  } = useContext(PageContext);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 568);

  const compassPosition: Position = {
    mobile: {
      en: {
        right: "3%",
        top: "10%",
      },
      ar: {
        left: "3%",
        top: "10%",
      },
    },
    desktop: {
      en: {
        bottom: "5%",
        left: "2%",
      },
      ar: {
        bottom: "5%",
        right: "2%",
      },
    },
  };

  const slideVariants = {
    hidden: {
      opacity: 0,
      x: currentLanguage === "ar" ? "100%" : "-100%",
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: currentLanguage === "ar" ? "100%" : "-100%",
    },
  };

  const unitSlideVariants = {
    hidden: {
      opacity: 0,
      x: currentLanguage === "ar" ? "-100%" : "100%",
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: currentLanguage === "ar" ? "-100%" : "100%",
    },
  };

  const slideUpVariants = {
    hidden: {
      opacity: 0,
      y: "100%",
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: "100%",
    },
  };

  const amenityCards = getAmenityCards(
    activeAmenitiesButtons,
    residencyPageAmenityPositions
  );

  const getStylesForDeviceAndLanguage = () => {
    if (isMobileView) {
      return {
        right: "0%",
        left: "0%",
      };
    }

    if (currentLanguage === "ar") {
      return {
        right: "2%",
        left: "unset",
      };
    }

    return {
      right: "unset",
      left: "2%",
    };
  };

  const deviceAndLanguageStyles = getStylesForDeviceAndLanguage();

  useEffect(() => {
    const preventDefaultScroll = (e: Event | TouchEvent) => {
      e.preventDefault();
    };

    if (activeButton === t(`nav.filters`)) {
      // Disable global scrolling
      document.addEventListener("touchmove", preventDefaultScroll as any, {
        passive: false,
      });
      document.body.style.overflow = "hidden";
    } else {
      // Enable global scrolling
      document.removeEventListener("touchmove", preventDefaultScroll as any);
      document.body.style.overflow = "";
    }

    return () => {
      // Cleanup: Enable global scrolling when component unmounts
      document.removeEventListener("touchmove", preventDefaultScroll as any);
      document.body.style.overflow = "";
    };
  }, [activeButton, t]);

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };

    setBodyHeight();

    window.addEventListener("resize", setBodyHeight);

    return () => {
      window.removeEventListener("resize", setBodyHeight);
    };
  }, []);

  useEffect(() => {
    addBreadcrumb(Pages.ResidencyPage);
  }, []);

  const handleUnitClick = () => {
    if (activeButton !== "unitInformation") {
      setActiveButton("unitInformation");
    } else {
      setActiveButton(null);
    }
  };

  return (
    <div className={styles.backgroundImage}>
      <div className={styles.container}>
        {amenityCards.map((amenity) => (
          <AmenitieCard
            key={amenity.key}
            iconType={amenity.iconType}
            top={amenity.position.top}
            left={amenity.position.left}
          />
        ))}

        <ZoomToolbar />
        <AnimatePresence>
          {activeButton === t(`nav.filters`) &&
          currentPage === "ResidencyPage" ? (
            <motion.div
              variants={slideVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
              className={styles.propertyFilterAnimatedContainer}
              style={{
                ...deviceAndLanguageStyles,
                pointerEvents: "none",
              }}
            >
              <PropertyFilters locationName={currentPage} />
            </motion.div>
          ) : null}
        </AnimatePresence>
        <NavigationTab locationName={t(`locations.residency`)} />
        <HorizontalTaletMobileFilters />

        <AnimatePresence>
          {activeButton === "unitInformation" ? (
            <motion.div
              variants={unitSlideVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
              className={styles.animatedContainer}
              style={{
                right: currentLanguage !== "ar" ? 0 : "unset",
                left: currentLanguage === "ar" ? 0 : "unset",
              }}
            >
              <UnitInformations />
            </motion.div>
          ) : null}
        </AnimatePresence>
        <AnimatePresence>
          <AnimatePresence>
            {activeButton === "unitInformation" && isMobile ? (
              <motion.div
                variants={slideUpVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.3 }}
                style={{
                  position: "fixed",
                  bottom: "0%",
                  width: "100%",
                  height: "100%",
                  zIndex: 3000,
                }}
              >
                {isMobileView ? (
                  <MobileUnitInformations />
                ) : (
                  <TabletUnitInformations />
                )}
              </motion.div>
            ) : null}
          </AnimatePresence>
        </AnimatePresence>
        <div className={styles.property} onClick={handleUnitClick} />
        <Compass position={compassPosition} />
      </div>
    </div>
  );
};

export default ResidencyPage;
