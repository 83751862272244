import { useContext } from "react";
import { PageContext } from "../../../contexts";
import styles from "./Captions.module.css";
import { useTranslation } from "react-i18next";

const Captions: React.FC = () => {
  const { t } = useTranslation();
  const { areCaptionsOn } = useContext(PageContext);

  if (!areCaptionsOn) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={`${styles.caption} ${styles.livingArea}`}>
        {t(`captions.livingArea`)}
      </div>
      <div className={`${styles.caption} ${styles.diningRoom}`}>
        {t(`captions.diningRoom`)}
      </div>
      <div className={`${styles.caption} ${styles.pool}`}>
        {t(`captions.pool`)}
      </div>
      <div className={`${styles.caption} ${styles.driver}`}>
        {t(`captions.driver`)}
      </div>
      <div className={`${styles.caption} ${styles.toilet}`}>
        {t(`captions.toilet`)}
      </div>
      <div className={`${styles.caption} ${styles.kitchen}`}>
        {t(`captions.kitchen`)}
      </div>
      <div className={`${styles.caption} ${styles.majlis}`}>
        {t(`captions.majlis`)}
      </div>
      <div className={`${styles.caption} ${styles.parking}`}>
        {t(`captions.parking`)}
      </div>
    </div>
  );
};

export default Captions;
