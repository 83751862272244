import React, { useState } from "react";
import styles from "./RoomTab.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  onRoomSelect: (room: string) => void;
}

const RoomTab: React.FC<Props> = ({ onRoomSelect }) => {
  const { t } = useTranslation();
  const rooms = [
    t("rooms.kitchen"),
    t("rooms.bathroom"),
    t("rooms.livingRoom"),
    t("rooms.office"),
    t("rooms.balcony"),
  ];

  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

  const handleButtonClick = (index: number) => {
    setSelectedButtonIndex(index);
    onRoomSelect(rooms[index]);
  };

  return (
    <div className={styles.container}>
      {rooms.map((room, index) => (
        <button
          key={index}
          className={
            selectedButtonIndex === index
              ? styles.selectedButton
              : styles.button
          }
          onClick={() => handleButtonClick(index)}
        >
          {room}
        </button>
      ))}
    </div>
  );
};

export default RoomTab;
