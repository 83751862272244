import React, { useState } from "react";
import styles from "./FloorTab.module.css";
import { useTranslation } from "react-i18next";

const FloorTab: React.FC = () => {
  const { t } = useTranslation();
  // Define a state to keep track of the selected button index
  // -1 means no button is selected
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(-1);

  // Define the button labels
  const buttons = [
    t(`button.roofFloor`),
    t(`button.firstFloor`),
    t(`button.groundFloor`),
  ];

  // Function to handle button click
  const handleButtonClick = (index: number) => {
    // If the clicked button is already selected, deselect it
    // Otherwise, select the clicked button
    setSelectedButtonIndex(selectedButtonIndex === index ? -1 : index);
  };

  return (
    <div className={styles.container}>
      {buttons.map((label, index) => (
        <button
          key={index}
          className={
            selectedButtonIndex === index
              ? styles.selectedButton
              : styles.button
          }
          onClick={() => handleButtonClick(index)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default FloorTab;
