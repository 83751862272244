import React, { useContext, useRef } from "react";
import MapFiltersButton from "../MapFiltersButton/MapFiltersButton";
import styles from "./MapFilters.module.css";
import { useTranslation } from "react-i18next";
import { PageContext } from "../../../contexts";

const MapFilters: React.FC = () => {
  const { t } = useTranslation();
  const { activeFilterButtons, setActiveFilterButtons } =
    useContext(PageContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const toggleButton = (name: string) => {
    setActiveFilterButtons((prevButtons) => ({
      ...prevButtons,
      [name]: !prevButtons[name],
    }));
  };

  const filterButtonNames = [
    "landmarks",
    "residential",
    "retail",
    "health",
    "business",
    "religious",
    "education",
  ];

  return (
    <div className={styles.container} ref={containerRef}>
      {filterButtonNames.map((name, index) => (
        <MapFiltersButton
          key={index}
          name={t(`filters.${name}`)}
          isActive={activeFilterButtons[name]}
          toggleActive={() => toggleButton(name)}
        />
      ))}
    </div>
  );
};

export default MapFilters;
