import { Pages } from "../contexts/PageContext";

export const getPageDisplayNames = (t: (key: string) => string) => {
  return {
    [Pages.HomePage]: t("locations.homePage"),
    [Pages.GlobePage]: t("locations.earth"),
    [Pages.CountryPage]: t("locations.saudiArabia"),
    [Pages.CityPage]: t("locations.city"),
    [Pages.CommunityPage]: t("locations.community"),
    [Pages.ResidencyPage]: t("locations.residency"),
    [Pages.HousePlanPage]: t("locations.unit"),
  };
};
