import { useContext } from "react";
import styles from "./HorizontalTabletHousePlanOptions.module.css";
import { PageContext } from "../../../contexts";

const HorizontalTabletHousePlanOptions: React.FC = () => {
  const { isVRMode, toggleVRMode } = useContext(PageContext);
  return (
    <div className={styles.container}>
      <button className={styles.button}>GF</button>
      <button className={styles.button} onClick={toggleVRMode}>
        {isVRMode ? "Plan" : "3D"}
      </button>
    </div>
  );
};

export default HorizontalTabletHousePlanOptions;
