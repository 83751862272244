import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import AmenitiesButton from "../AmenitiesButton/AmenitiesButton";
import styles from "./MobileAmenities.module.css";
import { CloseAmenitiesIcon } from "../../../icons/icons";
import { useTranslation } from "react-i18next";
import { PageContext } from "../../../contexts";

interface Props {
  onClose: () => void;
}

const MobileAmenities: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const { activeAmenitiesButtons, setActiveAmenitiesButtons } =
    useContext(PageContext);

  useEffect(() => {
    // Function to handle touch events
    const handleTouchStart = (e: TouchEvent) => {
      if (sliderRef.current && !sliderRef.current.contains(e.target as Node)) {
        props.onClose();
      }
    };

    // Add touchstart event listener
    document.addEventListener("touchstart", handleTouchStart);

    // Cleanup
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
    if (currentLanguage === "ar" && sliderRef.current) {
      const sliderElement = sliderRef.current;
      sliderElement.scrollLeft = sliderElement.scrollWidth; // Set the scroll position to the end
    }
  }, [currentLanguage]);

  const toggleButton = (name: string) => {
    setActiveAmenitiesButtons((prevButtons) => ({
      ...prevButtons,
      [name]: !prevButtons[name],
    }));
  };

  const amenitiesButtonNames = [
    "hospital",
    "park",
    "retail",
    "pocketPark",
    "mosque",
    "school",
  ];

  const closeButtonStyle: CSSProperties = {
    marginRight: currentLanguage === "ar" ? "unset" : "8px",
    marginLeft: currentLanguage === "ar" ? "8px" : "unset",
  };

  const closeButton = (
    <button
      className={styles.closeButton}
      style={closeButtonStyle}
      onClick={props.onClose}
    >
      <CloseAmenitiesIcon color="#111018" />
    </button>
  );

  const amenitiesButtons = amenitiesButtonNames.map((name, index) => (
    <AmenitiesButton
      key={index}
      name={t(`amenities.${name}`)}
      isActive={activeAmenitiesButtons[name]}
      toggleActive={() => toggleButton(name)}
    />
  ));

  const sliderItems = [closeButton, ...amenitiesButtons];

  const displayItems =
    currentLanguage === "ar" ? [...sliderItems].reverse() : sliderItems;

  return (
    <div className={styles.listContainer} ref={sliderRef}>
      <ul className={styles.list}>
        {displayItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default MobileAmenities;
