import React, { useState, useRef, useEffect } from "react";
import styles from "./MobileTabletRangeSlider.module.css";
import { ArrowDownIcon, RangeButtonIcon } from "../../../icons/icons";

interface Props {
  availability: number[];
  label: string;
  options: string[];
  minValue: number;
  maxValue: number;
}

const MobileTabletRangeSlider: React.FC<Props> = (props) => {
  const { availability, options, minValue, maxValue } = props;
  const [selectedMinValue, setSelectedMinValue] = useState(minValue);
  const [selectedMaxValue, setSelectedMaxValue] = useState(maxValue);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const minThumbRef = useRef<HTMLDivElement>(null);
  const maxThumbRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (maxThumbRef.current) {
      const thumbWidth = maxThumbRef.current.offsetWidth;
      const trackWidth = document.querySelector(".track")?.clientWidth || 0;
      const initialLeftPosition =
        (selectedMaxValue / maxValue) * trackWidth - thumbWidth;
      maxThumbRef.current.style.left = `${initialLeftPosition}px`;
    }
  }, []);

  const handleDragStart = (
    e: React.TouchEvent<HTMLDivElement>,
    thumb: "min" | "max"
  ) => {
    e.preventDefault();
    const onTouchMove = (e: TouchEvent) => handleDrag(e, thumb);
    const onTouchEnd = () => {
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    };

    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("touchend", onTouchEnd);
  };

  const handleDrag = (e: TouchEvent, thumb: "min" | "max") => {
    const track = document.querySelector(".track");
    if (track) {
      const rect = track.getBoundingClientRect();
      let x = e.touches[0].clientX - rect.left;

      // Calculate thumb width including padding
      const thumbWidth =
        thumb === "max" ? maxThumbRef.current?.offsetWidth || 0 : 0;

      // Adjust x for max thumb
      if (thumb === "max") {
        x = Math.max(0, Math.min(x - thumbWidth, rect.width - thumbWidth));
      } else {
        x = Math.max(0, Math.min(x, rect.width));
      }

      const value = (x / rect.width) * maxValue;

      if (thumb === "min") {
        setSelectedMinValue(Math.min(value, selectedMaxValue));
      } else {
        setSelectedMaxValue(Math.max(value, selectedMinValue));
      }
    }
  };

  return (
    <div className={styles.container}>
      <div
        className="track"
        style={{
          position: "relative",
          height: "20px",
          background: "transparent",
        }}
      >
        {availability.map((value, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              bottom: 0,
              left: `${(index / availability.length) * 100}%`,
              width: `calc(${100 / availability.length}% - 8px)`,
              marginLeft: "2px",
              marginRight: "2px",
              height: `${value * 100}%`,
              background: "#4F5051",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              opacity: 0.5,
            }}
          />
        ))}
        <div
          style={{
            position: "absolute",
            left: `${(selectedMinValue / maxValue) * 100}%`,
            width: `${
              ((selectedMaxValue - selectedMinValue) / maxValue) * 100
            }%`,
            height: "3px",
            bottom: "0px",
            background: "#D27BFA",
          }}
        />
        <div
          className="thumb"
          ref={minThumbRef}
          onTouchStart={(e) => handleDragStart(e, "min")}
          style={{
            position: "absolute",
            left: `${(selectedMinValue / maxValue) * 100}%`,
            padding: "8px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#D27BFA",
            bottom: "-10px",
          }}
        >
          <RangeButtonIcon />
        </div>
        <div
          className="thumb"
          ref={maxThumbRef}
          onTouchStart={(e) => handleDragStart(e, "max")}
          style={{
            position: "absolute",
            left: `${(selectedMaxValue / maxValue) * 100}%`,
            padding: "8px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#D27BFA",
            bottom: "-10px",
          }}
        >
          <RangeButtonIcon />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className={styles.priceRangeValue}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className={styles.option}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <ArrowDownIcon />
          </div>
          {selectedMinValue.toFixed(2)}
        </div>
        <div className={styles.priceRangeValue}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className={styles.option}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <ArrowDownIcon />
          </div>
          {selectedMaxValue.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default MobileTabletRangeSlider;
