import styles from "./Help.module.css";
import { CSSProperties, useContext, useRef, useState } from "react";
import { PageContext } from "../../../contexts";
import { CloseIcon, HelpIcon } from "../../../icons/icons";
import FAQItem from "./FAQItem/FAQItem";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const Help: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { setActiveButton } = useContext(PageContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  const [isHovered, setIsHovered] = useState(false);

  const containerStyle: CSSProperties & { [key: string]: any } = {
    right: currentLanguage === "ar" ? "unset" : "0%",
    left: currentLanguage === "ar" ? "0%" : "unset",
    "--direction": currentLanguage === "ar" ? "-100%" : "100%",
  };

  const closeButtonStyle: CSSProperties = {
    right: currentLanguage === "ar" ? "unset" : "3%",
    left: currentLanguage === "ar" ? "3%" : "unset",
  };

  const headerStyle: CSSProperties = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
  };

  const descriptionStyle: CSSProperties = {
    textAlign: currentLanguage === "ar" ? "right" : "left",
  };

  const handleClose = () => {
    setActiveButton(null); // Reset the activeButton state
  };

  if (isMobile) {
    return null;
  }

  const faqData = [
    {
      question: t(`help.changePlan`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.trialAvailable`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.cancellationPolicy`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.addInfo`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.getInvoice`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.affordableVersion`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.changeAccount`),
      answer: t(`help.trialAvailableResponse`),
    },
    {
      question: t(`help.platformSecure`),
      answer: t(`help.trialAvailableResponse`),
    },
  ];

  return (
    <div className={styles.container} style={containerStyle} ref={containerRef}>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={styles.closeButton}
        style={closeButtonStyle}
        onClick={handleClose}
      >
        <CloseIcon color={isHovered ? "#000" : "#fff"} />
      </button>
      <div className={styles.header} style={headerStyle}>
        <HelpIcon color="#D27BFA" />
        <span className={styles.title}>{t(`help.helpServices`)}</span>
      </div>
      <p className={styles.description} style={descriptionStyle}>
        {t(`help.description`)}
      </p>
      <div className={styles.faq}>
        {faqData.map((item, index) => (
          <FAQItem
            key={index}
            index={index}
            question={item.question}
            answer={item.answer}
            expanded={expandedIndex === index}
            setExpandedIndex={setExpandedIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default Help;
