import { useContext, useState } from "react";
import styles from "./HorizontalTabletRoomTab.module.css";
import { PageContext } from "../../../contexts";
import { useTranslation } from "react-i18next";

const HorizontalTabletRoomTab: React.FC = () => {
  const { t } = useTranslation();
  const rooms = [
    t("rooms.kitchen"),
    t("rooms.bathroom"),
    t("rooms.livingRoom"),
    t("rooms.office"),
    t("rooms.balcony"),
  ];
  const { isVRMode, setSelectedRoom } = useContext(PageContext);

  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

  const handleButtonClick = (index: number) => {
    setSelectedButtonIndex(index);
    setSelectedRoom(rooms[index]); // Update the selectedRoom state in the context
  };

  if (!isVRMode) {
    return null;
  }

  return (
    <div className={styles.container}>
      {rooms.map((room, index) => (
        <button
          key={index}
          className={
            selectedButtonIndex === index
              ? styles.selectedButton
              : styles.button
          }
          onClick={() => handleButtonClick(index)}
        >
          {room}
        </button>
      ))}
    </div>
  );
};

export default HorizontalTabletRoomTab;
