import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { AmenetiesIcon, FiltersIcon, RadiusIcon } from "../../../icons/icons";
import styles from "./HorizontalTaletMobileFilters.module.css";
import { isTablet } from "react-device-detect";
import MobileFilterServices from "../MobileFilterServices/MobileFilterServices";
import { PageContext } from "../../../contexts";
import MobileAmenities from "../MobileAmenities/MobileAmenities";
import { useTranslation } from "react-i18next";

const HorizontalTaletMobileFilters: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { setSelectedFilter, selectedFilter, currentPage } =
    useContext(PageContext);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    const handleOrientationChange = (event: MediaQueryListEvent) => {
      setIsPortrait(event.matches);
    };

    mediaQuery.addEventListener("change", handleOrientationChange);

    // Call the handler once to set the initial orientation
    handleOrientationChange(mediaQuery as unknown as MediaQueryListEvent);

    // Clean up the event listener when the component is unmounted
    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  if (!isTablet || isPortrait) {
    return null;
  }

  const handleCloseMobileAmenities = () => {
    setSelectedFilter(null);
  };

  const handleFilterClick = () => {
    setSelectedFilter("MobileFilters");
  };

  const handleAmenitiesClick = () => {
    setSelectedFilter("MobileAmenities");
  };

  const containerStyle: CSSProperties = {
    right: currentLanguage === "ar" ? "2%" : "unset", // unset left when language is "ar"
    left: currentLanguage === "ar" ? "unset" : "2%", // set right to 2% when language is "ar"
  };

  const filterRowStyle: CSSProperties = {
    flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
  };

  return (
    <div className={styles.container} style={containerStyle}>
      {currentPage !== "CommunityPage" ? (
        <div className={styles.filterRow} style={filterRowStyle}>
          {selectedFilter === "MobileFilters" ? (
            <MobileFilterServices onClose={handleCloseMobileAmenities} />
          ) : (
            <button className={styles.filter} onClick={handleFilterClick}>
              <FiltersIcon />
            </button>
          )}
        </div>
      ) : null}
      {currentPage !== "CommunityPage" && currentPage !== "ResidencyPage" ? (
        <div className={styles.filterRow} style={filterRowStyle}>
          <button className={styles.filter}>
            <RadiusIcon />
          </button>
        </div>
      ) : null}

      <div className={styles.filterRow} style={filterRowStyle}>
        {selectedFilter === "MobileAmenities" ? (
          <MobileAmenities onClose={handleCloseMobileAmenities} />
        ) : (
          <button className={styles.filter} onClick={handleAmenitiesClick}>
            <AmenetiesIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default HorizontalTaletMobileFilters;
